<div class="app-body">
<div class="container">

    <main class="main d-flex align-items-center" >
      <div class="container">
        <div class="row">
          <div class="col-md-9 mx-auto">
            <div class="card-group">
              <div class="card p-4">
                <div class="card-body">
                    <form (ngSubmit)="submit()" #theForm="ngForm">
                    <h1> <b>Bienvenido</b> </h1>
                    <p class="text-muted">Iniciar sesión en su cuenta</p>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fa fa-user"></i></span>
                      </div>
                      <input [(ngModel)]="login.email" #email="ngModel" required name="email"
                      type="text" placeholder="Usuario"
                      [ngClass]="{'is-invalid' : (email.dirty || email.touched)  && email.errors?.required}"
                      class="form-control" autocomplete="off" id="email">
                    </div>
                    <div class="input-group mb-4">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fa fa-lock"></i></span>
                      </div>
                      <input [(ngModel)]="login.password" #password="ngModel" name="password" required 
                      [ngClass]="{'is-invalid' : (password.dirty || password.touched)  && password.errors?.required}"
                          type="password" class="form-control" id="password" placeholder="Contraseña">
                    </div>


                    
                   
                      
                    <div class="row">
                      <div class="col-5">
                        <button type="submit" class="btn btn-primary"
                                [ngClass]="{'btn-success': (disableButton === false), 'btn-primary': (disableButton === true)}"
                                    [disabled]="!theForm.form.valid || disableButton">
                                    <i *ngIf="disableButton" class="fas fa-spin fa-cog"></i>
                                    INGRESAR</button>
                      </div>

                    
                      
                    </div>
                  </form>
                </div>
              </div>
              <div class="card text-white py-5 d-md-down-none" style="width:30%; background-color: #ff6c04;">
                <div class="card-body text-center">
                  <div  style="margin-top: -10px;">
                    <h2>{{informacion.titulo}}</h2>
                    <br>
                    <img src="{{informacion.logo}}"
                            style="width: 270px;">
                         
                    <p style="padding-top: 20px">{{informacion.descripcion}}</p>
                  </div>
                </div>
              </div>
            </div>

            <br>
            <div class="row tex-center">
                <div class="col-1"></div>
                <div class="col-10">
                    <div *ngIf="invalidUser" class="alert alert-danger" role="alert" >
                        Usuario y/o contraseña incorrecta 
                      </div>
                </div>
            </div>
            

          </div>

          

        </div>
      </div>
    </main>

</div>

  </div>
  
