<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-gray" >

                    <div class="row">
                        <div class="col-12">
                            <span class="letra-cabecera">Monitoreo de conductores</span>
                        </div>
                    </div>
                
                    <br><br>
                    <div  id="mapa" ></div>


                </div>
            </div>
        </div>
    </div>
</div>
