import { Component, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/proyectos/cliente/services/cliente.service';
import { GiroService } from '../../giro/services/giro.service';
import { ZonaService } from '../../zona/services/zona.service';
import { EmpleadoService } from 'src/app/empleados/empleado/services/empleado.service';
import { VehiculoService } from '../../vehiculo/services/vehiculo.service';
import { PedidosService } from 'src/app/configuracion/pedidos/service/pedidos.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductoService } from '../../producto/service/producto.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';



@Component({
  selector: 'app-pedido-form',
  templateUrl: './pedido-form.component.html',
  styleUrls: ['./pedido-form.component.scss']
})
export class PedidoFormComponent implements OnInit {


  conductores: any = [];
  vehiculos: any = [];
  productos: any = [];
  clientes: any = [];
  zonas: any = [];
  giros: any = [];

  formGroup: FormGroup;

  page: number = 1;

  public IMG =  environment.cloudinary;


  totalCobrar: any = 0;

  productList: any = [];


  public registerFormPesada = this.fb.group({
    imagen: ['', Validators.required],
    producto: ['',  Validators.required],
    precio: ['', Validators.required],
    cantidad: ['', Validators.required],
  });



  idEdit: any;
  productoEdit: any;
  cantidadEdit : any;
  imagenEdit: any; 
  precioEdit: any;


  public formSubmitedPesadaEdit = false;
  public registerFormPesadaEdit = this.fb.group({
    _id: ['',  Validators.required],
    producto: ['', Validators.required],
    cantidad: ['', Validators.required],
    precio: ['', Validators.required],
    imagen: ['', Validators.required],
  });


  public  randomNumber =  'N - ' + Math.floor(100000 + Math.random() * 900000);
  constructor(public clienteService: ClienteService,
    public giroService: GiroService,
    public conductorService: EmpleadoService,
    public vehiculoService: VehiculoService,
    protected fb: FormBuilder,
    public productService: ProductoService,
    public pedidoService: PedidosService,
    public router: Router,
    public zonaService: ZonaService){


      this.formGroup = this.fb.group({
        num_guia: ['', [Validators.required]],
        nombre: ['', [Validators.required]],
        num_documento: ['', [Validators.required]],
        productos: [this.productList],
        id_zona: ['', [Validators.required]],
        id_cliente: ['', [Validators.required]],
        id_conductor: ['', [Validators.required]],
        documentoConductor: ['', [Validators.required]],

        id_vehiculo: ['', [Validators.required]],

        telefono: ['', [Validators.required]],
        direccion: ['', [Validators.required]],
        latitud: ['', [Validators.required]],
        longitud: ['', [Validators.required]],
        id_giro_negocio: ['', [Validators.required]],
      })    

  }


  ngOnInit(): void {
      this.cargarClientes();
      this.cargarConductor();
      this.cargarGiros();
      this.cargarVehiculos();
      this.cargarZonas();
      this.cargarProductos();

  }



  onSubmit(){

    if (this.formGroup.valid) { 
      this.pedidoService.create(this.formGroup.value).subscribe(reps => {
        console.log(reps);
         Swal.fire('Muy bien',` pedido registrado correctamente` , 'success');
         this.router.navigate(["/admin/pedidos.list"])

      })
    } else {
      Swal.fire('Es importante',` agregar toda la información` , 'error');
      
    }

    console.log(this.formGroup.value);

    
  }






  cargarGiros(){
    this.giroService.export().subscribe(resp => {
      this.giros = resp['data']
      console.log(resp['data']);
    })
  }

  cargarZonas(){
    this.zonaService.export().subscribe(resp => {
      this.zonas = resp['data'];
      console.log(resp['data']);

    })
  }

  cargarClientes(){
    this.clienteService.export().subscribe(resp => {
      this.clientes = resp['data'];
      console.log(resp['data']);

    })
  }

  cargarVehiculos(){
    this.vehiculoService.export().subscribe(resp => {
      this.vehiculos = resp['data'];
      console.log(resp['data']);

    })
  }


  cargarProductos(){
    this.productService.export().subscribe(resp => {
      this.productos = resp['data'];
      console.log(resp['data']);

    })
  }



  cargarConductor(){
    this.conductorService.export().subscribe(resp => {
      console.log(resp);
      this.conductores = resp['usuario'];
      console.log(resp['usuario']);

    })
  }

  onSelectionChange(event){
    console.log(event.value);
    this.clienteService.getById({ "_id": event.value}).subscribe(data => {
      console.log(data['cliente'][0])

      this.formGroup.controls.nombre?.setValue(data['cliente'][0]['nombre'])
      this.formGroup.controls.id_cliente?.setValue(data['cliente'][0]['_id'])

      this.formGroup.controls.telefono?.setValue(data['cliente'][0]['telefono'])
      this.formGroup.controls.num_documento?.setValue(data['cliente'][0]['num_documento'])
      this.formGroup.controls.id_giro_negocio?.setValue(data['cliente'][0]['id_giro_negocio'])
      

      this.formGroup.controls.direccion?.setValue(data['cliente'][0]['direccion'])
      this.formGroup.controls.latitud?.setValue(data['cliente'][0]['latitud'])
      this.formGroup.controls.longitud?.setValue(data['cliente'][0]['longitud'])
    })
  }



  onSelectionChangeConductor(event){
    console.log(event.value);
    this.conductorService.getById({ "_id": event.value}).subscribe(data => {
      console.log(data['usuario'][0])
      this.formGroup.controls.id_vehiculo?.setValue(data['usuario'][0]['id_vehiculo'])
      this.formGroup.controls.documentoConductor?.setValue(data['usuario'][0]['numero_documento'])
      // this.formGroup.controls.longitud?.setValue(data['cliente'][0]['longitud'])
    })
  }


  addProductPush(data: any){
    console.log(data);
    Swal.fire('Producto agreado',` fue agregado correctamente` , 'success');

    const product = {
      _id: data._id,
      producto: data.nombre,
      cantidad: 1,
      imagen: data.imagen,
      precio: data.precio
    }

    this.productList.push(product)
    this.calcularTotales();
    console.log(this.productList);

  }



  eliminar(data){
    console.log(this.productList);
    let tmp:any[] = [];
    this.productList.map( (x:any) => (x._id != data._id) ? tmp.push(x):null); 
    this.productList = tmp;
    console.log(this.productList);
    this.calcularTotales()
  }


  editarPesada(data: any){
    console.log(data);
     this.idEdit = data._id;
     this.productoEdit = data.producto;
     this.cantidadEdit = data.cantidad;
     this.imagenEdit = data.imagen; 
     this.precioEdit = data.precio;
  }


  updatePesada(){
    this.calcularTotales();
    let tmp:any[] = [];
    this.productList.map( (x:any) => (x._id != this.idEdit) ? tmp.push(x):null); 
    this.productList = tmp;
    console.log(this.productList);
    this.formSubmitedPesadaEdit = true;
    console.log(this.registerFormPesadaEdit.value);
    this.productList.push(this.registerFormPesadaEdit.value)
    const total = this.registerFormPesadaEdit.value.bruto;
    console.log(total);
    console.log(this.productList);
    //Calcula totales
    this.calcularTotales();
    this.productList.map((item,i) => item._id = String(i));
    // console.log(totalBruto);   
    Swal.fire('Producto Editado',` fue editado correctamente` , 'success');
    console.log(this.productList);
    if( this.registerFormPesadaEdit.invalid) {
      return;
    }
    this.calcularTotales();
  }

  

  calcularTotales(){
    // this.totalCobrar = this.productList.reduce((sum, value) => (typeof value.cantidad == "number" ? sum + value.cantidad : sum), 0);
    // this.totalTara = this.pesadasTemp.reduce((sum, value) => (typeof value.tara == "number" ? sum + value.tara : sum), 0);
    // this.totalNeto = this.pesadasTemp.reduce((sum, value) => (typeof value.neto == "number" ? sum + value.neto : sum), 0);
    // this.totalCantidad =  this.pesadasTemp.reduce((sum, value) => (typeof value.cantidad == "number" ? sum + value.cantidad : sum), 0);
  }



  registrarPedido(){
    console.log('hola');
    console.log(this.formGroup.value);
  }

}
