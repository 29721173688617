<div class="modal-header btn-success">
    <h4 class="modal-title text-white">{{ formTitle }}</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">
    <form (ngSubmit)="onSubmit()" id="formUser" [formGroup]="formGroup">


       

            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre:</mat-label>
                    <input name="nombre" autocomplete="off" matInput formControlName="nombre" >
                </mat-form-field>
            </div>

            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Descripción:</mat-label>
                    <textarea name="description" autocomplete="off" matInput formControlName="descripcion" rows="2" ></textarea>
                </mat-form-field>
            </div>


            <div class="row">
                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Precio:</mat-label>
                        <input name="nombre" autocomplete="off" matInput formControlName="precio" >
                    </mat-form-field>
                </div>
    
                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Stock:</mat-label>
                        <input name="nombre" autocomplete="off" matInput formControlName="stock" >
                    </mat-form-field>
                </div>
            </div>



            <div class="row">
                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Unidad Medida:</mat-label>
                        <input name="nombre" autocomplete="off" matInput formControlName="u_medida" >
                    </mat-form-field>
                </div>
    
                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Categoria:</mat-label>
                        <mat-select  formControlName="id_categoria">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let wType of categorias" [value]="wType._id">
                                {{wType.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Categoria:</mat-label>
                        <input name="nombre" autocomplete="off" matInput formControlName="id_categoria" >
                    </mat-form-field> -->
                </div>
            </div>


        

            <div class="" *ngIf="!statusActive" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Estado cargo:</mat-label>
                    <mat-select  formControlName="estado">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let wType of statusEntity" [value]="wType.value">
                            {{wType.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="row" style="text-align: center; justify-content: center; ">
                <div class="col-12">
                    <img [src]="imgSelect" alt="" width="150px" > 
                </div>
                <div class="col-12" style="padding-top: 10px;">
                    <input type="file" class="cz-file-drop-input" accept="image/*" (change)="imgSelected($event)">
                </div>
            </div>


    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="disableControl" (click)="closeMOdal()">CANCELAR</button>
    <button type="submit" [ngClass]="{'btn-dark': (disableControl === false), 'btn-warning': (disableControl === true)}"
        class="btn btn-primary" [disabled]="disableControl" form="formUser">
        <i *ngIf="disableControl" class="fas fa-spin fa-cog"></i>
        {{ disableControl === true ? 'ENVIANDO' : 'ACEPTAR' }}</button>
</div>