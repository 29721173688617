<div class="modal-header btn-success">
    <h4 class="modal-title text-white">{{ formTitle }}</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">
    <form (ngSubmit)="onSubmit()" id="formUser" [formGroup]="formGroup">

            <div class="row" >

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Codigo:</mat-label>
                        <input type="text" name="nombre" autocomplete="off" matInput formControlName="cod_conductor" >
                    </mat-form-field>
                </div>


                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Nombres:</mat-label>
                        <input type="text" name="nombre" autocomplete="off" matInput formControlName="nombre" >
                    </mat-form-field>
                </div>
            </div>


            <div class="row" >

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Tipo de documento:</mat-label>
                        <mat-select  formControlName="tipo_documento">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let wType of tipo_documento" [value]="wType.value">
                                {{wType.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Numero de documento:</mat-label>
                        <input type="text" name="nombress" autocomplete="off" matInput formControlName="numero_documento" >
                    </mat-form-field>
                </div>
            </div>


            

            <div class="row">
                <div class="col">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Correo:</mat-label>
                        <input type="email" name="nombre1" autocomplete="off" matInput formControlName="email" >
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Celular:</mat-label>
                        <input name="nombre2" autocomplete="off" matInput formControlName="celular" >
                    </mat-form-field>
                </div>
            </div>


            <div class="row" *ngIf="!statusActive" >
                <div class="col-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Estado</mat-label>
                        <mat-select  formControlName="estado">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let wType of estado" [value]="wType.label">
                                {{wType.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Fecha Nacimiento:</mat-label>
                        <input matInput datePickerFormat="YYYY/MM/DD" [matDatepicker]="picker" (change)="change($event)"  formControlName="fecha_nacimiento">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>



            <div class="row">
                <div class="col">
                    <!-- <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Vehiculo:</mat-label>
                        <input type="email" name="nombre1" autocomplete="off" matInput formControlName="id_vehiculo" >
                    </mat-form-field> -->

                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Vehiculos</mat-label>
                        <mat-select  formControlName="id_vehiculo">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let wType of vehiculos" [value]="wType._id">
                                {{wType.cod_vehiculo}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Licencia:</mat-label>
                        <input name="nombre2" autocomplete="off" matInput formControlName="num_licencia" >
                    </mat-form-field>
                </div>
            </div>

            

            <div class="row">
                <div class="col-12" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Contraseña:</mat-label>
                        <input type="text" name="nombress" autocomplete="off" matInput formControlName="password_show" >
                    </mat-form-field>
                </div>
            </div>

    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="disableControl" (click)="closeMOdal()">CANCELAR</button>
    <button type="submit" [ngClass]="{'btn-dark': (disableControl === false), 'btn-warning': (disableControl === true)}"
        class="btn btn-primary" [disabled]="disableControl" form="formUser">
        <i *ngIf="disableControl" class="fas fa-spin fa-cog"></i>
        {{ disableControl === true ? 'ENVIANDO' : 'ACEPTAR' }}</button>
</div>