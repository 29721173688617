import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Empleado } from 'src/app/entities/modulos/empleado';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


const base_url = environment.url;


@Injectable({
  providedIn: 'root'
})
export class EmpleadoService {


  


  formGroup: FormGroup;

  constructor(protected http: HttpClient,
    protected router: Router,
    protected fb: FormBuilder) {

      this.formGroup = this.fb.group({
        cod_conductor: ['', [Validators.required]],
        nombre: ['', [Validators.required]],
        tipo_documento: ['', [Validators.required]],
        numero_documento: [''],
        email: ['', [Validators.required]],
        celular: ['', [Validators.required]],
        fecha_nacimiento: ['', [Validators.required]],
        id_vehiculo: ['', [Validators.required]],
        num_licencia: ['', [Validators.required]],
        estado: [''],
        password_show: ['', [Validators.required]],
      })      
      
      
    }



    get form (): FormGroup { return this.formGroup; }

    set fillForm(usuario: Empleado) {
    this.formGroup.get('cod_conductor').setValue(usuario.cod_conductor)
    this.formGroup.get('nombre').setValue(usuario.nombre)
    this.formGroup.get('tipo_documento').setValue(usuario.tipo_documento)
    this.formGroup.get('numero_documento').setValue(usuario.numero_documento)
    this.formGroup.get('email').setValue(usuario.email)
    this.formGroup.get('celular').setValue(usuario.celular)
    this.formGroup.get('fecha_nacimiento').setValue(usuario.fecha_nacimiento)
    this.formGroup.get('id_vehiculo').setValue(usuario.id_vehiculo)
    this.formGroup.get('num_licencia').setValue(usuario.num_licencia)
    this.formGroup.get('estado').setValue(usuario.estado);

    this.formGroup.get('password_show').setValue(usuario.password_show);

    }


    cargarEmpleados(desde: number = 0){
    const url = `${ base_url}user/obtener?desde=${desde}`; 
    return this.http.get<any>(url)
    }

    getById(usuario: Empleado): Observable<Empleado> {
    return this.http.post<Empleado>(environment.baseUrl + 'user/showByID', usuario)
    }

    create(empleado: Empleado): Observable<Empleado> {
    return this.http.post<Empleado>(`${environment.baseUrl}user/create`, empleado)
    }

    edit(empleado: Empleado): Observable<Empleado> {
    return this.http.post<Empleado>(environment.baseUrl + 'user/update/' + empleado._id, empleado);
    }

    delete(empleado: Empleado): Observable<Empleado> {
    return this.http.delete<Empleado>(environment.baseUrl + 'user/' + empleado._id)
    }



    export(): Observable<any> {
      return this.http.get<any>(environment.baseUrl + 'user/exportar')
    }


    buscar(
      tipo: 'personal'|'tecnico'|'sede' |'usuario' |'dni' | 'email' | 'compra' | '_id',
      termino: string
    ) {
      const url = `${base_url}todo/coleccion/${tipo}/${termino}`;
      return this.http.get<any[]>(url)
      .pipe(
        map( (resp: any) => resp.resultados)
      );
    }
  
    
}
