import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Cargo } from 'src/app/entities/modulos/cargo';
import { Util } from 'src/app/utils/helpers/util';
import { ProductoService } from '../service/producto.service';
import { DeleteProductoComponent } from '../modal/delete-producto/delete-producto.component';
import { CreateProductoComponent } from '../modal/create-producto/create-producto.component';
import { EditProductoComponent } from '../modal/edit-producto/edit-producto.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-producto-list',
  templateUrl: './producto-list.component.html',
  styleUrls: ['./producto-list.component.scss']
})
export class ProductoListComponent implements OnInit {


  public data: any = [];
  public producto: any[] = [];
  public productoTemp: any[] = [];
  
  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalProducto: number = 0;

  public IMG =  environment.cloudinary;

  constructor(public productoService: ProductoService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.cargarProductos();
  }


  cargarProductos(){
    this.cargando = true; 
    this.productoService.cargarProductos(this.desde)
    .subscribe(({total, producto}) => {
        this.totalProducto = total;
        if(producto.length !== 0) { 
          this.producto = producto;
          console.log(producto);
          this.productoTemp = producto;
        }
        this.cargando = false;
    })
  }


  openCreate(){
    const modalRef = this.modalService.open(CreateProductoComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then(res => {
        this.cargarProductos();
      })
  }


  openEdit(data: Cargo) {
    const modalEdit = this.modalService.open(EditProductoComponent, { size: 'lg', backdrop: 'static' })
    modalEdit.componentInstance.producto = data
    modalEdit.result.then(res => {
      // this.dataSource.updateTable(this.paginator.pageIndex)
      this.cargarProductos();
    })
  }

  openDelete(data: Cargo) {
    const deleteModal = this.modalService.open(DeleteProductoComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.producto = data
    deleteModal.result.then(res => {
      // this.dataSource.updateTable(this.paginator.pageIndex)
      this.cargarProductos();
    })
  }

  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalProducto) {
      this.desde -= valor;
    }
    this.cargarProductos();
  }


  export(){
    this.productoService.export()
        .subscribe(res => { Util.download(res['data'], 'productos'); console.log(res)});
  }


  buscar(termino: string) {
   
  }


}
