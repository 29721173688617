<div class="modal-header btn-success">
    <h4 class="modal-title text-white">{{ formTitle }}</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">
    <form (ngSubmit)="onSubmit()" id="formUser" [formGroup]="formGroup">

            <div class="row" >

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Codigo:</mat-label>
                        <input type="text" name="nombre" autocomplete="off" matInput formControlName="cod_vehiculo" >
                    </mat-form-field>
                </div>


                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Marca:</mat-label>
                        <input type="text" name="nombre" autocomplete="off" matInput formControlName="marca" >
                    </mat-form-field>
                </div>
            </div>


            <div class="row" >
                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Modelo:</mat-label>
                        <input type="text" name="nombress" autocomplete="off" matInput formControlName="modelo" >
                    </mat-form-field>
                </div>

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Placa:</mat-label>
                        <input type="text" name="nombress" autocomplete="off" matInput formControlName="placa" >
                    </mat-form-field>
                </div>
            </div>


            
            <div class="row" *ngIf="!statusActive" >
                <div class="col-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Estado</mat-label>
                        <mat-select  formControlName="estado">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let wType of estado" [value]="wType.label">
                                {{wType.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
               
            </div>


    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="disableControl" (click)="closeMOdal()">CANCELAR</button>
    <button type="submit" [ngClass]="{'btn-dark': (disableControl === false), 'btn-warning': (disableControl === true)}"
        class="btn btn-primary" [disabled]="disableControl" form="formUser">
        <i *ngIf="disableControl" class="fas fa-spin fa-cog"></i>
        {{ disableControl === true ? 'ENVIANDO' : 'ACEPTAR' }}</button>
</div>