<div class="modal-header btn-primary">
    <h4 class="modal-title text-white">{{ formTitle }}</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">
    <form (ngSubmit)="onSubmit()" id="formUser" [formGroup]="formGroup">


        <div class="" >
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Codigo zona:</mat-label>
                <input name="nombre" autocomplete="off" matInput formControlName="cod_zona" >
            </mat-form-field>
        </div>


            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre zona:</mat-label>
                    <input name="nombre" autocomplete="off" matInput formControlName="nombre" >
                </mat-form-field>
            </div>

            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Descripción cargo:</mat-label>
                    <textarea name="description" autocomplete="off" matInput formControlName="descripcion" rows="2" ></textarea>
                </mat-form-field>
            </div>


            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Poligono:</mat-label>
                    <textarea name="description" autocomplete="off" matInput formControlName="poligono" rows="8" ></textarea>
                </mat-form-field>
            </div>




            <div class="" *ngIf="!statusActive" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Estado cargo:</mat-label>
                    <!-- <input name="estado" autocomplete="off" matInput formControlName="estado" > -->
                    <mat-select  formControlName="estado">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let wType of statusEntity" [value]="wType.value">
                            {{wType.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="disableControl" (click)="closeMOdal()">CANCELAR</button>
    <button type="submit" [ngClass]="{'btn-dark': (disableControl === false), 'btn-warning': (disableControl === true)}"
        class="btn btn-primary" [disabled]="disableControl" form="formUser">
        <i *ngIf="disableControl" class="fas fa-spin fa-cog"></i>
        {{ disableControl === true ? 'ENVIANDO' : 'ACEPTAR' }}</button>
</div>