import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Empleado } from 'src/app/entities/modulos/empleado';
import { VehiculoService } from '../services/vehiculo.service';


@Component({
  selector: 'app-vehiculo-form',
  templateUrl: './vehiculo-form.component.html',
  styleUrls: ['./vehiculo-form.component.scss']
})
export class VehiculoFormComponent  implements OnInit {


  @Input() formGroup: FormGroup;
  @Output() submitEvent = new EventEmitter<Empleado>()
  @Output() closeEvent =  new EventEmitter<boolean>()
  @Input() disableControl: boolean
  @Input() formTitle: string
  refreshTable = false
  statusActive: boolean = false;



  estado = [
    {value: 'Activo', label: "1"},
    {value: 'Inactivo', label: "0"}
  ]

  public departamento: any [] = [];
  public cargo: any [] = [];

  constructor(protected fb: FormBuilder,
    protected activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public vehiculoService: VehiculoService,

    private dateAdapter: DateAdapter<Date>) {
      
      this.dateAdapter.setLocale('en-GB'); 

    }

  ngOnInit(): void {

    this.formGroup.reset();
    if(this.formTitle === 'EDITAR VEHICULO'){
      // this.formGroup.get('username').disable();
      this.statusActive = false;
      // this.formLDAP = false;
    } else{
      // this.newUser = false;
      this.statusActive = true;

    }
  }



  cargarDepartamento(){
    // this.departamentoService.cargarDepartamento().subscribe(resp => {
    //   console.log(resp)
    //   this.departamento = resp['departamento']
    // })
  }

  cargarCargo(){
    // this.cargoService.cargarCargo().subscribe(resp => {
    //   console.log(resp)
    //   this.cargo = resp['cargo']
    // })
  }



  onSubmit() {

      this.submitEvent.emit(this.formGroup.value)
      this.formGroup.reset();
  }


  change($event){
    console.log($event)
  }


  closeMOdal() {
    this.closeEvent.emit(this.refreshTable)
    this.modalService.dismissAll();
  }

}
