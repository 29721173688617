<div class="modal-header btn-success">
    <h4 class="modal-title text-white">{{ formTitle }}</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">
    <form (ngSubmit)="onSubmit()" id="formUser" [formGroup]="formGroup">




            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Codigo cliente</mat-label>
                    <input name="ruc" autocomplete="off" matInput formControlName="cod_cliente" >
                </mat-form-field>
            </div>


            <div class="row" >
                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Nombres:</mat-label>
                        <input name="ruc" autocomplete="off" matInput formControlName="nombre" >
                    </mat-form-field>
                </div>

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Apellidos:</mat-label>
                        <input name="razon" autocomplete="off" matInput formControlName="apellidos" >
                    </mat-form-field>
                </div>
            </div>


            <div class="row" >
                <div class="col-6" >
                    <!-- <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Tipo de documento:</mat-label>
                        <input name="ruc" autocomplete="off" matInput formControlName="tipo_documento" >
                    </mat-form-field> -->
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Tipo documento</mat-label>
                        <mat-select  formControlName="tipo_documento">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let wType of tipo_documento" [value]="wType.value">
                                {{wType.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Numero documento:</mat-label>
                        <input name="razon" autocomplete="off" matInput formControlName="num_documento" >
                    </mat-form-field>
                </div>
            </div>



            <div class="row" >
                <div class="col-6" >
                    <!-- <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Giro de negocio:</mat-label>
                        <input name="ruc" autocomplete="off" matInput formControlName="id_giro_negocio" >
                    </mat-form-field> -->
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Giro de negocio:</mat-label>
                        <mat-select  formControlName="id_giro_negocio">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let wType of giros" [value]="wType._id">
                                {{wType.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Telefono:</mat-label>
                        <input name="razon" autocomplete="off" matInput formControlName="telefono" >
                    </mat-form-field>
                </div>
            </div>


            <div class="row" >
                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Direccion: </mat-label>
                        <input name="ruc" autocomplete="off" matInput formControlName="direccion" >
                    </mat-form-field>
                </div>

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Zonas:</mat-label>
                        <mat-select  formControlName="id_zona">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let wType of zonas" [value]="wType._id">
                                {{wType.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>






            <div class="row" >
                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Latitud:</mat-label>
                        <input name="ruc" autocomplete="off" matInput formControlName="latitud" >
                    </mat-form-field>
                </div>

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Longitud:</mat-label>
                        <input name="razon" autocomplete="off" matInput formControlName="longitud" >
                    </mat-form-field>
                </div>
            </div>




            <!-- <div class="row">
                <div class="col-10" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>RUC:</mat-label>
                        <input  type="number" id="ruc" name="ruc" #ruc autocomplete="off" matInput formControlName="ruc" >
                    </mat-form-field>
                </div>
                <div class="col-2">
                    <button type="button" class="btn btn-danger" [disabled]="disableControl" (click)="buscarRuc(ruc.value)">CONSULTA RENIEC</button>
                </div>
            </div>



            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Razon Social:</mat-label>
                    <input name="ruc" autocomplete="off" matInput formControlName="razonSocial" >
                </mat-form-field>
            </div>





            <div class="row" >

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Condición:</mat-label>
                        <input name="ruc" autocomplete="off" matInput formControlName="condicion" >
                    </mat-form-field>
                </div>

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Departamento:</mat-label>
                        <input name="razon" autocomplete="off" matInput formControlName="departamento" >
                    </mat-form-field>
                </div>
            </div>


            <div class="row" >

                

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Provincia:</mat-label>
                        <input name="razon" autocomplete="off" matInput formControlName="provincia" >
                    </mat-form-field>
                </div>

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Distrito:</mat-label>
                        <input name="ruc" autocomplete="off" matInput formControlName="distrito" >
                    </mat-form-field>
                </div>
            </div>


            <div class="row" >

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Telefono:</mat-label>
                        <input type="number" name="nombre" autocomplete="off" matInput formControlName="telefono" >
                    </mat-form-field>
                </div>

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Correo:</mat-label>
                        <input type="email" name="nombre" autocomplete="off" matInput formControlName="correo" >
                    </mat-form-field>
                </div>
            </div> -->




            <!-- <div class="row" *ngIf="!statusActive" >
                <div class="col-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Estado</mat-label>
                        <mat-select  formControlName="estado">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let wType of estado" [value]="wType.value">
                                {{wType.label}}
                            </mat-option>
                        </mat-select>
                        
                    </mat-form-field>

                </div>
            </div> -->



          



    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="disableControl" (click)="closeMOdal()">CANCELAR</button>
    <button type="submit" [ngClass]="{'btn-dark': (disableControl === false), 'btn-warning': (disableControl === true)}"
        class="btn btn-primary" [disabled]="disableControl" form="formUser">
        <i *ngIf="disableControl" class="fas fa-spin fa-cog"></i>
        {{ disableControl === true ? 'ENVIANDO' : 'ACEPTAR' }}</button>
</div>