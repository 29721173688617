import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductoService } from '../../producto/service/producto.service';
import { PedidosService } from '../service/pedidos.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeletePedidoComponent } from '../modal/delete-pedido/delete-pedido.component';

@Component({
  selector: 'app-pedido-list',
  templateUrl: './pedido-list.component.html',
  styleUrls: ['./pedido-list.component.scss']
})
export class PedidoListComponent implements OnInit{


  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalPedidos: number = 0;

  public pedido: any[] = [];
  public pedidoTemp: any[] = [];

  constructor(public router: Router,
    private modalService: NgbModal,
    public pedidoService: PedidosService){

  }

  ngOnInit(): void {
    this.cargarPedidos();
    
  }


  openCreate(){
    this.router.navigateByUrl("/admin/pedidos.form");

  }



  cargarPedidos(){
    this.cargando = true; 
    this.pedidoService.cargarPedidos(this.desde)
    .subscribe(({total, pedidos}) => {
        this.totalPedidos = total;
        if(pedidos.length !== 0) { 
          this.pedido = pedidos;
          console.log(pedidos);
          this.pedidoTemp = pedidos;
        }
        this.cargando = false;
    })
  }



  
  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalPedidos) {
      this.desde -= valor;
    }
    this.cargarPedidos();
  }



  
  openDelete(data: any) {
    const deleteModal = this.modalService.open(DeletePedidoComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.pedido = data
    deleteModal.result.then(res => {
      this.cargarPedidos();
    })
  }


}
