<div class="modal-header btn-primary">
    <h4 class="modal-title">{{ formTitle }}</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <app-server-response-form *ngIf="serverResponseJSON" [response]="serverResponseJSON"></app-server-response-form>
    <form [formGroup]="formGroup" id="permissionForm" (ngSubmit)="submit()">
        <div class="row">
            <div class="col-sm-12">
                <div>
                    <mat-form-field style="width: 100%;" appearance="outline">
                        <mat-label>Nombre del perfil</mat-label>
                        <input matInput autocomplete="off" formControlName="displayName">
                        <mat-error *ngIf="formGroup.controls.displayName.invalid">Ingrese un nombre correcto</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div>
                    <mat-form-field style="width: 100%;" appearance="outline">
                        <mat-label>Descripción gg</mat-label>
                        <textarea matInput formControlName="description"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                Permisos<br />
                <mat-error *ngIf="hasNoPermission">Seleccione los permisos para este rol
                </mat-error>
            </div>

        </div>
        <div class="row">
            <div class="col-12" *ngIf="flatData; else loading">
                <!-- <ul> -->
                <mat-tree #tree [dataSource]="dataSource" [treeControl]="treeControl" [trackBy]="fn" class="tree-list">
                    <mat-nested-tree-node *matTreeNodeDef="let node">
                        <li class="mat-tree-node">
                            <button type="button" mat-icon-button disabled></button>
                            <mat-checkbox [disabled]="disableControl" [checked]="isSelected(node)"
                                (change)="leafItemSelectionToggle(node)">
                                <i class="{{ node.icon }}"></i> {{node.text}}</mat-checkbox>
                        </li>
                    </mat-nested-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div>
                                <button type="button" mat-icon-button matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.text">
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                    </mat-icon>
                                </button>
                                <mat-checkbox [disabled]="disableControl" [checked]="descendantsAllSelected(node)"
                                    (change)="changeItemSelectionToggle(node)"
                                    [indeterminate]="descendantsIndeterminateSelected(node)">
                                    <i class="{{ node.icon }}"></i> {{node.text}}
                                </mat-checkbox>
                            </div>
                            <ul style="padding-left: 18px;" [class.tree-invisible]="!treeControl.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </div>
            <ng-template #loading>
                <div class="col-4">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            </ng-template>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="disableControl" (click)="closeMOdal()">CANCELAR</button>
    <button type="submit" [ngClass]="{'btn-dark': (disableControl === false), 'btn-warning': (disableControl === true)}"
        class="btn btn-primary" [disabled]="disableControl" form="permissionForm">
        <i *ngIf="disableControl" class="fas fa-spin fa-cog"></i>
        {{ disableControl === true ? 'ENVIANDO' : 'ACEPTAR' }}</button>
</div>