<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-gray" >
                    <div class="row">
                        <div class="col-12">
                            <span class="letra-cabecera">Listado de PEDIDOS</span>
                        </div>
                    </div>
                
                        <div class="row mt-4">

                            <div class="col-12 text-right">
                                <mat-form-field class="w-25" appearance="outline">
                                    <mat-label>Buscar por guia pedido:</mat-label>
                                    <input name="proyecto" autocomplete="off" matInput >
                                </mat-form-field>
                            </div>

                            <div class="col-12 text-right">
                                <button class="btn btn-primary" (click)="openCreate()" ><i class="fas fa-plus mr-1"> </i> NUEVO</button>&nbsp;
                                <button  class="btn btn-success" ><i class="fas fa-file-excel mr-2"> </i>EXPORTAR</button>&nbsp;
                            </div>
                        </div>
                </div>






                <div class="card-body">




                    <div class="table-responsive">
                        <br>
                        <table class="table table-bordered">
                            <thead style="background-color: #ff6c04; color: #fff; font-weight: lighter;">
                                <tr>
                                    <!-- <th>Fecha de creación</th> -->
                                    <th>Nombre cliente</th>
                                    <th>Nombre conductor</th>
                                     <th>Dirección</th>
                                    <th>U Latitud</th>
                                    <th>Longitud</th>
                                    <th>Giro</th>
                                    <th>Estado</th>
                                    <th class="text-nowrap text-center">Acciones</th> 
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of pedido">

                                    <!-- <td>{{data.created | date: 'dd/MM/yyyy   HH:mm:ss'}}</td> -->
                                   
                                    <td>{{data.nombreCliente}}</td>
                                    <td>{{data.nombreConductor}}</td>

                                    <td>{{data.direccion}}</td>

                                    <td>{{data.latitud}}</td>
                                    <td>{{data.longitud}}</td>
                               

                                    <td>{{data.nombreGiro}}</td>
                                    <td *ngIf="data.estado == 'CANCELADO'"> <button class="btn btn-danger">CANCELADO</button> </td>
                                    <td *ngIf="data.estado == 'ENTREGADO'"> <button class="btn btn-primary">ENTREGADO</button> </td>
                                    <td *ngIf="data.estado == 'POR ENTREGAR'"> <button class="btn btn-warning">POR ENTREGAR</button> </td>
                                    <td *ngIf="data.estado == 'NO SE ENCONTRO CLIENTE'"> <button class="btn btn-dark">NO SE ENCONTRO CLIENTE</button> </td>
                                

                                    <td class="text-nowrap text-center">
                                        <button  class="cursor btn btn-danger mr-2" (click)="openDelete(data)"   > <i class="fas fa-trash-alt"> </i>  </button> 
                                    </td> 
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>

                <button  class="btn btn-secondary" (click)="cambiarPagina(-5)"> <i class="fas fa-arrow-left mr-1"> </i> Anteriores</button>
                    &nbsp;
                    <button  class="btn btn-success" (click)="cambiarPagina(5)">Siguientes  <i class="fas fa-arrow-right mr-1"> </i> </button> 

                </div>






            </div>
        </div>
    </div>
</div>
