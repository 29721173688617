<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-gray" >
                    <div class="row">
                        <div class="col-12">
                            <span class="letra-cabecera">Listado de PEDIDOS</span>
                        </div>
                    </div>
                
                        <div class="row mt-4">
                            <div class="col-12 text-right">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Seleccione conductor:</mat-label>
                                    <mat-select   (selectionChange)="onSelectionChangeConductor($event)">
                                        <mat-option>--</mat-option>
                                        <mat-option *ngFor="let wType of conductores" [value]="wType._id">
                                            {{wType.nombre}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <!-- <div class="col-12 text-right">
                                <input type="date" class=" form-control">
                            </div> -->


                            

                            <div class="col-12 text-right pt-3" >
                                <!-- <button class="btn btn-primary" (click)="cargarPedidos()" ><i class="fas fa-plus mr-1"> </i> CARGAR PEDIDO</button>&nbsp; -->
                            </div>
                        </div>
                </div>






                <div class="card-body">




                    <div class="table-responsive">
                        <br>
                        <table class="table table-bordered">
                            <thead style="background-color: #ff6c04; color: #fff; font-weight: lighter;">
                                <tr>
                                    <th>Fecha de creación</th>
                                    <th>Cliente</th>
                                    <th>Conductor</th>
                                    <th>Origen</th>
                                    <th>Destino</th>
                                    <th>Duración</th>
                                    <th>Kilometros</th>
                                    <th>Duración de conductor</th>
                                    <th>Punto Inicial</th>
                                    <th>Punto Final</th>
                                     
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of pedido">

                                    <td>{{data.created | date: 'dd/MM/yyyy   HH:mm:ss'}}</td>
                                   
                                    <td>{{data.nombreCliente}}</td>
                                    <td>{{data.nombreConductor}}</td>
                                    <td>{{data.origin_addresses}}</td>
                                    <td>{{data.destination_addresses}}</td>

                                    <td>{{data.duration}}</td>
                                    <td>{{data.distance}}</td>
                                    <td>{{data.durationUser}} mins</td>
                                    <td><a [href]="getGoogleMapsLink(data.latOrigin, data.lngOrigin)" target="_blank">Abrir en Google Maps</a></td>

                                    <td><a [href]="getGoogleMapsLink(data.latitud, data.longitud)" target="_blank">Abrir en Google Maps</a></td>



                                    


                                    
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>

                <button  class="btn btn-secondary" (click)="cambiarPagina(-10)"> <i class="fas fa-arrow-left mr-1"> </i> Anteriores</button>
                    &nbsp;
                    <button  class="btn btn-success" (click)="cambiarPagina(10)">Siguientes  <i class="fas fa-arrow-right mr-1"> </i> </button> 

                </div>


                <div style="padding: 20px;">
                    <h4 style="font-size: 20px; color: green; font-weight: bolder;">Resultados</h4>
                    <p>El tiempo optimo estimado por el algoritmo es de: <b style="color: blue; font-size: 16px;"> {{totalDuracion}} </b></p>
                    <p>El tiempo de recorrido por el usuario es de: <b style="color: red; font-size: 16px;"> {{totalDuracionUser}} </b> </p>
                </div>





            </div>
        </div>
    </div>
</div>
