import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Empleado } from 'src/app/entities/modulos/empleado';
import { Util } from 'src/app/utils/helpers/util';
import { VehiculoService } from '../services/vehiculo.service';
import { CreateVehiculoComponent } from '../modal/create-vehiculo/create-vehiculo.component';
import { EditVehiculoComponent } from '../modal/edit-vehiculo/edit-vehiculo.component';
import { DeleteVehiculoComponent } from '../modal/delete-vehiculo/delete-vehiculo.component';


@Component({
  selector: 'app-vehiculo-list',
  templateUrl: './vehiculo-list.component.html',
  styleUrls: ['./vehiculo-list.component.scss']
})
export class VehiculoListComponent implements OnInit {

  public data: any = [];


  public vehiculo: any[] = [];
  public vehiculoTemp: any[] = [];
  
  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalVehiculo: number = 0;

  constructor(public vehiculoService: VehiculoService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.cargarVehiculo();
  }


  cargarVehiculo(){
    this.cargando = true; 
    this.vehiculoService.cargarVerhiculos(this.desde)
    .subscribe(({total, vehiculo}) => {
        this.totalVehiculo = total;
        if(vehiculo.length !== 0) { 
          this.vehiculo = vehiculo;
          console.log(vehiculo);
          this.vehiculoTemp = vehiculo;
        }
        this.cargando = false;
    })
  }


  openCreate(){
    const modalRef = this.modalService.open(CreateVehiculoComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then(res => {
        this.cargarVehiculo();
      })
  }


  openEdit(data: Empleado) {
    const modalEdit = this.modalService.open(EditVehiculoComponent, { size: 'lg', backdrop: 'static' })
    modalEdit.componentInstance.vehiculo = data
    modalEdit.result.then(res => {
      this.cargarVehiculo();
    })
  }

  openDelete(data: Empleado) {
    const deleteModal = this.modalService.open(DeleteVehiculoComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.vehiculo = data
    deleteModal.result.then(res => {
      this.cargarVehiculo();
    })
  }

  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalVehiculo) {
      this.desde -= valor;
    }
    this.cargarVehiculo();
  }


  export(){
    this.vehiculoService.export()
        .subscribe(res => { Util.download(res['data'], 'vehiculo'); console.log(res)});
  }


  buscar(termino: string) {
   
  }

}
