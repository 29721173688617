import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Cargo } from '../../../entities/modulos/cargo';
import { Producto } from 'src/app/entities/modulos/producto';
import { ProductoService } from '../service/producto.service';
import { environment } from 'src/environments/environment';
import { CategoriaService } from '../../categoria/service/categoria.service';
// import { DepartamentoService } from '../../departamento/services/departamento.service';

interface HtmlInputEvent extends Event{
  target : HTMLInputElement & EventTarget;
} 


@Component({
  selector: 'app-producto-form',
  templateUrl: './producto-form.component.html',
  styleUrls: ['./producto-form.component.scss']
})
export class ProductoFormComponent  implements OnInit {
  @Input() formGroup: FormGroup;
  @Output() submitEvent = new EventEmitter<Producto>()
  @Output() closeEvent =  new EventEmitter<boolean>()
  @Input() disableControl: boolean
  @Input() formTitle: string
  refreshTable = false
  statusActive: boolean = false;

  statusEntity = [
    {value: '1', label: 'Activo'},
    {value: '0', label: 'Inactivo'},
]

  public despartamentos: any [] = [];
  public categorias: any [] = [];
  public file :File;
  public imgSelect : String | ArrayBuffer = `${environment.cloudinary}productos/default_kfyemj.JPG`;



  constructor(protected fb: FormBuilder,
              protected activeModal: NgbActiveModal,
              private modalService: NgbModal,
              public productoService: ProductoService,
              public categoriaService: CategoriaService,
              ) { }

  ngOnInit(): void {

    this.cargarCategorias();

    this.formGroup.reset();
    if(this.formTitle === 'EDITAR PRODUCTO'){
      // this.formGroup.get('username').disable();
      this.statusActive = false;
      // this.formLDAP = false;
    } else{
      // this.newUser = false;
      this.statusActive = true;

    }

  }


  cargarCategorias(){
    this.categoriaService.export().subscribe( resp => {
      console.log(resp['data']);
      this.categorias = resp['data'];

    })
  }

  onSubmit() {
    console.log(this.formGroup.value);
    // this.refreshTable = true
    // this.formGroup.get('id').setValue(this.selectedRols)
    // if (this.selectedRols.length<1)
    //   this.formGroup.get('id').setErrors({'ee': true})
    // this.formSubmit = true;
    if (this.formGroup.valid) {
      this.submitEvent.emit(this.formGroup.value)
      this.formGroup.reset();
    }
  }



  closeMOdal() {
    this.closeEvent.emit(this.refreshTable)
    this.modalService.dismissAll();
  }


  imgSelected(event: HtmlInputEvent){
    if(event.target.files  && event.target.files[0]){
        this.file = <File>event.target.files[0];

        const reader = new FileReader();
        reader.onload = e => this.imgSelect= reader.result;
        reader.readAsDataURL(this.file);
       console.log(this.file);
       this.formGroup.controls.imagen.setValue(this.file)
        
    }
    
  }

}
