import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PedidosService } from '../../pedidos/service/pedidos.service';
import { EmpleadoService } from 'src/app/empleados/empleado/services/empleado.service';

@Component({
  selector: 'app-reporte-entregas',
  templateUrl: './reporte-entregas.component.html',
  styleUrls: ['./reporte-entregas.component.scss']
})
export class ReporteEntregasComponent implements OnInit{

  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalPedidos: number = 0;

  public pedido: any[] = [];
  public pedidoTemp: any[] = [];

  conductores: any = [];

  public filtros = {
    id_conductor: "",
    created: ""
  }

    public totalDuracion: any = '';
    public totalDuracionUser: any = '';

  constructor(public router: Router,
    private modalService: NgbModal,
    public conductorService: EmpleadoService,
    public pedidoService: PedidosService){

  }


  ngOnInit(): void {
    this.cargarPedidos();
    this.cargarConductor();
  }

  cargarPedidos(){
    this.cargando = true; 

    
    this.pedidoService.cargarReportePedidos(this.desde, this.filtros )
    .subscribe(({total, pedidos, totalDuracion, totalDuracionUser}) => {
        this.totalPedidos = total;
        // if(pedidos.length !== 0) { 

        this.totalDuracion = totalDuracion;
        this.totalDuracionUser = totalDuracionUser;
          this.pedido = pedidos;
          console.log(pedidos);
          this.pedidoTemp = pedidos;
        // }
        this.cargando = false;
    })
  }


  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalPedidos) {
      this.desde -= valor;
    }
    this.cargarPedidos();
  }


  getGoogleMapsLink(lat: string, lng: string): string {
    // return `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDmdiHoF2AHDy6T2TV6hTP6klJQIiLgrso`;
    return `https://www.google.com/maps?q=${lat},${lng}`;
  }



  cargarConductor(){
    this.conductorService.export().subscribe(resp => {
      console.log(resp);
      this.conductores = resp['usuario'];
      console.log(resp['usuario']);

    })
  }



  onSelectionChangeConductor(event){
    console.log(event.value);
    this.conductorService.getById({ "_id": event.value}).subscribe(data => {
      console.log(data['usuario'][0])
      this.filtros.id_conductor = data['usuario'][0]['_id'];
      console.log(data['usuario'][0]['_id']);

    this.cargarPedidos();

      // this.formGroup.controls.id_vehiculo?.setValue(data['usuario'][0]['id_vehiculo'])
      // this.formGroup.controls.documentoConductor?.setValue(data['usuario'][0]['numero_documento'])
      // this.formGroup.controls.longitud?.setValue(data['cliente'][0]['longitud'])
    })


  }

}
