export const environment = {
  production: false,
  // baseUrl: 'https://gestionservices.herokuapp.com/',
  baseUrl: 'https://curtisservices.onrender.com/',
  url: 'https://curtisservices.onrender.com/',

  // baseUrl: 'http://localhost:3000/',
  // url: 'http://localhost:3000/',
  cloudinary: 'https://res.cloudinary.com/laimedev/image/upload/v1683405466/curtis_app/',
  appTitle: 'GESTION PRY.'
};