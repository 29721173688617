
<div class="main-content">
    <div class="row">

<div class="modal-body">
    <form (ngSubmit)="onSubmit()" id="formUser" [formGroup]="formGroup">

        <h4 style="color: #272624; font-weight: bolder;">REGISTRAR UN PEDIDO</h4>


        <div class="row">



        <div class="col-4 sm-6" >
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Ingrese nº guia:</mat-label>
                <input name="nombre"  [(ngModel)]="randomNumber" autocomplete="off" matInput  formControlName="num_guia" disabled readonly>
            </mat-form-field>
        </div>


        <div class="col-4 sm-6" >

            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Seleccione zona:</mat-label>
                <mat-select   formControlName="id_zona">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let wType of zonas" [value]="wType._id">
                        {{wType.nombre}} - {{wType.cod_zona}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            </div>

            <div class="col-4 sm-6" >

                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Seleccione cliente:</mat-label>
                    <mat-select   (selectionChange)="onSelectionChange($event)" formControlName="id_cliente">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let wType of clientes" [value]="wType._id">
                            {{wType.nombre}} - {{wType.num_documento}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>




            <div class="col-4" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Direccion:</mat-label>
                    <input name="razon" autocomplete="off" matInput formControlName="direccion" >
                </mat-form-field>
            </div>

            <div class="col-4" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Latitud:</mat-label>
                    <input name="razon" autocomplete="off" matInput formControlName="latitud" >
                </mat-form-field>
            </div>

            <div class="col-4" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Longitud:</mat-label>
                    <input name="razon" autocomplete="off" matInput formControlName="longitud" >
                </mat-form-field>
            </div>





        <div class="col-4 sm-6" >
            
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Seleccione conductor:</mat-label>
                <mat-select   (selectionChange)="onSelectionChangeConductor($event)" formControlName="id_conductor">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let wType of conductores" [value]="wType._id">
                        {{wType.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            </div>



            <div class="col-4 sm-6" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Seleccione vehiculo:</mat-label>
                    <mat-select formControlName="id_vehiculo" >
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let wType of vehiculos" [value]="wType._id">
                            {{wType.marca}} - {{wType.cod_vehiculo}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                </div>

                


                <div class="col-4" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Documento Conductor:</mat-label>
                        <input name="razon" autocomplete="off" matInput formControlName="longitud" formControlName="documentoConductor" >
                    </mat-form-field>
                </div>




        </div>

    </form>
    
        
        <hr>


        <div style="float: right;">
            <button class="btn btn-primary"  data-toggle="modal" data-target=".bs-example-modal" > <i class="fa fa-plus  m-r-10"> </i> Agregar Producto</button>
            <br> 
        </div>







        <div class="table-responsive" style="padding-top: 20px ;">
            <table class="table table-bordered">
                <thead style="background-color: #ff6c04;">
                    <tr style="color: #fff;">
                        <th>Imagen</th>
                        <th>Producto</th>
                        <th>Precio</th>
                        <th>Cantidad</th>
                        <th class="text-nowrap">Quitar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let pesadasTemp of productList | paginate: { itemsPerPage: 10, currentPage: page } ">
                        <td><img [src]="IMG+'productos/'+pesadasTemp.imagen" width="25px"></td>
                        <td>{{pesadasTemp.producto}}</td>
                        <td>{{pesadasTemp.precio}}</td>
                        <td>{{pesadasTemp.cantidad}}</td>

                        <td class="text-nowrap text-center">
                            <a style="color: #fff;"  data-toggle="modal" data-target="#responsive-modal" (click)="editarPesada(pesadasTemp)"  class="mr-3 cursor btn btn-warning m-r-10 model_img "  (click)="editarPesada(pesadasTemp)" > <i class="fa fa-edit"> </i>  </a>
                            <button class="cursor btn btn-danger" (click)="eliminar(pesadasTemp)" > <i class="fa fa-trash"> </i>  </button>
                        </td>

                    </tr>
                    
                </tbody>

              
            </table>


            <pagination-controls (pageChange)="page = $event" 
            previousLabel="Anterior"
            nextLabel="Siguiente"></pagination-controls>


            <!-- <p>Total a pagar: <b>{{totalCobrar}}  </b> </p>
            <button class="btn btn-success" (click)="calcularTotales()">clcular</button> -->


            <div style="float: left;" class="pt-5">
                <button  type="button" class="btn btn-success" (click)="onSubmit()" > <i class="fa fa-plus  m-r-10" > </i> Registrar pedido</button>
                <br> 
            </div>


        </div>





      


        <div class="modal fade bs-example-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" >
            <div class="modal-dialog modal-lg">
    
    
                <div class="modal-content">

            <div class="modal-header" style="background-color: #ff6c04;">
                <h4 class="modal-title" style="color: #ffff;"  id="myLargeModalLabel">AGREGAR PRODUCTOS  </h4>
                <button type="button" class="close"  data-dismiss="modal" aria-hidden="true">×</button>
            </div>

            <div class="modal-body">



                <div class="table-responsive" style="padding-top: 20px ;">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <!-- <th>Index</th> -->
                                <th>Producto</th>
                                <th>Precio</th>
                                <th>Medida</th>
                                <th>Imagen</th>
                                <!-- <th>Cantidad</th> -->
                                <th class="text-nowrap">Agregar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pesadasTemp of productos | paginate: { itemsPerPage: 10, currentPage: page } ">
                                <!-- <td>{{pesadasTemp._id}}</td> -->
                                <td>{{pesadasTemp.nombre}}</td>
                                <td>{{pesadasTemp.precio}}</td>
                                <td>{{pesadasTemp.u_medida}}</td>
                                <!-- <td>{{pesadasTemp.imagen}}</td> -->
                                
                                <td><img [src]="IMG+'productos/'+pesadasTemp.imagen" width="25px"></td>
                                <!-- <td><input type="text" class="form-control" placeholder="Cantidad"></td> -->

                                <td class="text-nowrap text-center">
                                    <button class="cursor btn btn-success" (click)="addProductPush(pesadasTemp)" > <i class="fa fa-plus"> </i>   </button>
                                </td>
    
                            </tr>
                            
                        </tbody>
    
                      
                    </table>
    
    
                    <pagination-controls (pageChange)="page = $event" 
                    previousLabel="Anterior"
                    nextLabel="Siguiente"></pagination-controls>
    
    
    
                </div>



            </div>

            <div class="modal-footer">

            
                    <button id="close" type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">Cerrar</button>
          
            </div>

                </div>
            </div>
        </div>
        



      
        <!-- MODAL DE EDITAR -->

        <div class="modal fade bs-example-modal-lg"  id="responsive-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg"  >
    
                <div class="modal-content" >
    
                    <div class="modal-header" style="background-color: #ff6c04;">
                        <h4 class="modal-title" style="font-weight: bold;"  id="myLargeModalLabel">EDITAR PRODUCTO AGREGADO</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
    
    
                    <form class="form-horizontal form-material"
                    id="loginform" 
                    [formGroup]="registerFormPesadaEdit"  
                    autocomplete="off"
                    (ngSubmit)="updatePesada()">
    
    
                    <div class="modal-body" >
                        <br>

                        <div class="row">
                            <div class="col-12 text-center">
                                <img [src]="IMG+'productos/'+imagenEdit" width="75px">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                    <small> PRODUCTO</small>
                                    <input  class="form-control"  type="text"  [(ngModel)]="imagenEdit" formControlName="imagen"  style="display: none;">
                                    <input  class="form-control"  type="text"  [(ngModel)]="productoEdit" formControlName="producto"  disabled>
                            </div>

                            <div class="col-6">
                                <small> PRECIO</small>
                                <input  class="form-control"  type="text"  [(ngModel)]="precioEdit" formControlName="precio" disabled>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-6">
                                    <small> PRECIO</small>
                                    <input  class="form-control"  type="text"  [(ngModel)]="precioEdit" formControlName="precio" disabled>
                            </div>

                            <div class="col-6">
                                <small> CANTIDAD</small>
                                <input  class="form-control"  type="text"  [(ngModel)]="cantidadEdit" formControlName="cantidad" >
                            </div>
                        </div>
    
                        <!-- <div class="col-lg-12 col-xlg-6 col-md-6" style="float: right;">            
                            <div class="form-group" >
                                <small> INGRESE CANTIDAD</small>
                                <input    style="font-weight: bold; font-size: 20px;" type="number" name="nombre1" class="form-control"  [(ngModel)]="cantidadEdit" formControlName="cantidad" >
                            </div>
    
                            <div class="form-group">
                                <small>PESO TARA</small>
                                <input style="font-weight: bold; font-size: 20px;"  type="number" name="tara1" class="form-control"  [(ngModel)]="taraEdit"  formControlName="tara">
                            </div>
                        </div>
                        <div class="col-lg-12 col-xlg-12 col-md-12"> 
                            <div class="form-group" >
                                <small>INGRESE PESO BRUTO (Kg)</small>
                                <input style="font-weight: bold; text-align: center; font-size: 30px; color: red;" type="number" name="nombre" class="form-control"  [(ngModel)]="brutoEdit" formControlName="bruto" >
                            </div>
                            <div class="form-group">
                                <small style="text-align: center;">PESO NETO (Kg)</small>
                                <input style="font-weight: bold; text-align: center; font-size: 30px; color: green;" readonly type="number" name="nombre" class="form-control"  value="{{ brutoEdit - (cantidadEdit * (taraEdit + paletaEdit))}}"  [ngModel]="brutoEdit - (cantidadEdit * (taraEdit + paletaEdit))"  formControlName="neto"   >
                            </div>
                        </div> -->


                    </div>
    
    
    
    
                    <div class="modal-footer">
    
                        <!-- <div style="margin-right: 50px;">
                            <small *ngIf="!registerFormPesadaEdit.valid" style="color: red; font-size: 14px; float: right;">Complete todos los campos *REQUERIDO  <i class="fa fa-close"> </i></small>
                            <small *ngIf="registerFormPesadaEdit.valid" style="color: green; font-size: 14px; float: right;">Excelente, ya puede editar. <i class="fa fa-check"> </i></small>    
                        </div> -->
    
    
                        <button    type="submit"  class="btn btn-success waves-effect text-left" #closeBtn >Editar producto</button>
                        <button id="close" type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">Cerrar</button>
    
                    </div>
    
                </form>
    
    
                </div>
            </div>
        </div>

        
        <!-- MODAL DE EDITAR  -->




           
</div>


</div>
</div>
