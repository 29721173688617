import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-pedido',
  templateUrl: './edit-pedido.component.html',
  styleUrls: ['./edit-pedido.component.scss']
})
export class EditPedidoComponent {

}
