import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Pedidos } from 'src/app/entities/modulos/pedidos';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


const base_url = environment.url;

@Injectable({
  providedIn: 'root'
})
export class PedidosService {
  formGroup: FormGroup;


  constructor(protected http: HttpClient,
              protected router: Router,
              protected fb: FormBuilder) {

                this.formGroup = this.fb.group({
                  num_guia: ['', [Validators.required]],
                  precio_total: ['', [Validators.required]],
                  descripcion: ['', [Validators.required]],
                  precio: ['', [Validators.required]],
                  stock: ['', [Validators.required]],
                  u_medida: ['', [Validators.required]],
                  imagen: ['', [Validators.required]],
                  estado: [''],
                })      
                
                
              }



  get form (): FormGroup { return this.formGroup; }

  set fillForm(pedidos: Pedidos) {
  this.formGroup.get('num_guia').setValue(pedidos.num_guia)
  this.formGroup.get('precio_total').setValue(pedidos.precio_total)
  this.formGroup.get('id_zona').setValue(pedidos.id_zona)
  this.formGroup.get('id_cliente').setValue(pedidos.id_cliente)
  this.formGroup.get('id_conductor').setValue(pedidos.id_conductor)
  this.formGroup.get('id_vehiculo').setValue(pedidos.id_vehiculo)
  this.formGroup.get('productos').setValue(pedidos.productos)
  this.formGroup.get('estado').setValue(pedidos.estado)

  
  }


 cargarPedidos(desde: number = 0){
    const url = `${ base_url}pedidos/show?desde=${desde}`; 
    return this.http.get<any>(url)
  }


  cargarReportePedidos(desde: number = 0, formData: any){
    const url = `${ base_url}pedidos/showReportePedidos?desde=${desde}`; 
    return this.http.post<any>(url, formData)
  }



  getById(cargo: Pedidos): Observable<Pedidos> {
    return this.http.post<Pedidos>(environment.baseUrl + 'pedidos/showByID', cargo)
  }

  // create(cargo: Producto): Observable<Producto> {
  //   return this.http.post<Producto>(`${environment.baseUrl}producto`, cargo)
  // }


  // fd.append('num_guia',data.num_guia);
  //   fd.append('nombre',data.nombre);
  //   fd.append('num_documento',data.num_documento);
  //   fd.append('productos',data.productos);
  //   fd.append('id_zona',data.id_zona);
  //   fd.append('id_cliente',data.id_cliente);
  //   fd.append('id_conductor',data.id_conductor);

  //   fd.append('documentoConductor',data.documentoConductor);
  //   fd.append('id_vehiculo',data.id_vehiculo);
  //   fd.append('id_conductor',data.id_conductor);
  //   fd.append('telefono',data.telefono);
  //   fd.append('direccion',data.direccion);
  //   fd.append('latitud',data.latitud);
  //   fd.append('longitud',data.longitud);
  //   fd.append('id_giro_negocio',data.id_giro_negocio);



  create(empleado: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}pedidos`, empleado)
    }
    

  create0001(data: Pedidos): Observable<Pedidos> {
    const fd = new FormData();
    // fd.append('id_categoria',data.id_categoria);
    // fd.append('nombre',data.nombre);
    // fd.append('descripcion',data.descripcion);
    // fd.append('stock',data.stock);
    // fd.append('precio',data.precio);
    // fd.append('u_medida',data.u_medida);
    // fd.append('imagen',data.imagen);
    return this.http.post<Pedidos>(`${environment.baseUrl}producto`, fd)
  }



  edit(cargo: Pedidos): Observable<Pedidos> {
    return this.http.post<Pedidos>(environment.baseUrl + 'producto/update/' + cargo._id, cargo);
  }

  delete(cargo: Pedidos): Observable<Pedidos> {
    return this.http.delete<Pedidos>(environment.baseUrl + 'pedidos/' + cargo._id)
  }


  export(): Observable<Pedidos> {
    return this.http.get<Pedidos>(environment.baseUrl + 'producto/exportar')
  }


  buscar(
    tipo: 'cargo'|'tecnico'|'sede' |'usuario' |'dni' | 'email' | 'compra' | '_id',
    termino: string
  ) {
    const url = `${base_url}todo/coleccion/${tipo}/${termino}`;
    return this.http.get<any[]>(url)
    .pipe(
      map( (resp: any) => resp.resultados)
    );
  }


}
