<div class="modal-header btn-primary">
    <h4 class="modal-title text-white">{{ formTitle }}</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form (ngSubmit)="onSubmit()" id="formKpi" [formGroup]="formGroup">
        <div class="form-row">
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Kpi</mat-label>
                    <input autocomplete="off" matInput formControlName="kpi">
                </mat-form-field>
            </div>
            <div class="form-group col-md-6">
                 <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Contador Radar</mat-label>
                    <input autocomplete="off" matInput formControlName="contador_radar">
                </mat-form-field>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Contador Huawei</mat-label>
                    <input autocomplete="off" matInput formControlName="contador_huawei">
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="disableControl" (click)="closeMOdal()">CANCELAR</button>
    <button type="submit" [ngClass]="{'btn-dark': (disableControl === false), 'btn-warning': (disableControl === true)}"
        class="btn btn-primary" [disabled]="disableControl" form="formKpi">
        <i *ngIf="disableControl" class="fas fa-spin fa-cog"></i>
        {{ disableControl === true ? 'ENVIANDO' : 'ACEPTAR' }}</button>
</div>