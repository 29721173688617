<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">

                <div class="card-body p-4 justify-content-between">
                    <div class="row">
                        <div class="col-6">
                            <h4>Bienvenido, {{ user }}</h4>
                        </div>
                        <div class="col-6 text-right">
                            <h4 class="">{{ today | today }}</h4>
                        </div>
                    </div>

                    

                </div>
                <div class="card-footer bg-white">
                    <div class="text-center">
                        <br><br>
                        <img  style="width: 600px;" src="assets/img/truck-delivery.gif" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>

     

   





</div>