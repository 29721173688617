import { Component } from '@angular/core';

@Component({
  selector: 'app-create-pedido',
  templateUrl: './create-pedido.component.html',
  styleUrls: ['./create-pedido.component.scss']
})
export class CreatePedidoComponent {

}
