<div class="modal-header btn-primary">
    <h4 class="modal-title text-white">ELIMINAR PERFIL</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
<div class="row">
    <div *ngIf="deleted=== false; else elseBlock" class="col-12">
        Eliminar Perfil <strong>{{ rol.displayName }}</strong>. ¿Está seguro de realizar esta acción?
        <div *ngIf="deleting">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
    <ng-template #elseBlock>
        <div class="col-12">
            Se eliminó correctamente
        </div>
    </ng-template>

</div>
    </div>
</div>
<div class="modal-footer">
    <button [disabled]="deleting" type="button" (click)="closeMOdal()" class="btn btn-success" >CANCELAR</button>&nbsp;&nbsp;
    <button [disabled]="deleting" *ngIf="deleted === false" type="button" (click)="deleteRol()" class="btn btn-primary"
    [ngClass]="{'btn-dark': (deleting === false), 'btn-warning': (deleting === true)}" >
        <i *ngIf="deleting" class="fas fa-spin fa-cog"></i>
        ELIMINAR</button>
    
</div>