import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { Lugar } from '../../../interfaces/interfaces';
import { UserService } from 'src/app/services/security/user.service';
import { EmpleadoService } from 'src/app/empleados/empleado/services/empleado.service';

interface RespMarcadores {
  [ key: string ]: Lugar
}


@Component({
  selector: 'app-monitoreo-real-time',
  templateUrl: './monitoreo-real-time.component.html',
  styleUrls: ['./monitoreo-real-time.component.scss']
})
export class MonitoreoRealTimeComponent implements OnInit {

  mapa: mapboxgl.Map;


  public lugares: Lugar[];


  constructor(public empleadoService: EmpleadoService){}

  ngOnInit() {


  //   this.lugares = [{
  //     "nombre": 'JOSE QUISPE',
  //     "lat": -11.973558,
  //     "lng": -76.996935,
  //   },
  //   {
  //     nombre: 'ELIZABETH',
  //     lat: -11.967321,
  //     lng: -76.998411,
  //   },
  //   {
  //     nombre: 'JUAN',
  //     lat: -11.971400,
  //     lng: -77.007085,
  //   },
  //   {
  //     nombre: 'FAKE',
  //     lat: -12.1388771,
  //     lng: -76.9758268,
  //   },
  //  ];
  // console.log(lal);

    this.empleadoService.export().subscribe(resp => {
      const usuariosConCoordenadas = resp['usuario']
        .filter(usuario => usuario.latitud && usuario.longitud)
        .map(usuario => {
          return {
            nombre: usuario.nombre,
            lat: parseFloat(usuario.latitud),
            lng: parseFloat(usuario.longitud)
          };
        });
    
      this.lugares = usuariosConCoordenadas;

      console.log(this.lugares);
    });

    setTimeout(() => {
      this.crearMapa();
    }, 1000);
   
  }


  crearMapa() {
    
    (mapboxgl as any).accessToken = 'pk.eyJ1IjoiYWxhaW1lczY0IiwiYSI6ImNrbDhvNTg5bjJ3b2cycHVpMWM5Nm42ZzYifQ.bmnvgwqnmNOi29MhH6s7sw';

    this.mapa = new  mapboxgl.Map({
      container: 'mapa',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-76.996935, -11.973558],
      zoom: 12.8
    })


    for(const marcador of this.lugares) {
      this.agregarMarcador(marcador);
    }

  }


agregarMarcador(marcador: Lugar) {

  const html = `<h3> <br>${marcador.nombre} </h3> `

  const customPopup = new mapboxgl.Popup({
    offset: 25,
    closeOnClick: false
  }).setHTML(html)

    const marker = new mapboxgl.Marker({
      draggable: true,
      color: marcador.color
    })
    .setLngLat([marcador.lng, marcador.lat])
    .setPopup(customPopup)
    .addTo(this.mapa);


    marker.on('drag', () => {
      const lngLat = marker.getLngLat();
      console.log(lngLat);
    })

  }



  escucharSockets(){

  }




}
