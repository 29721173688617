import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Vehiculo } from 'src/app/entities/modulos/vehiculo';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const base_url = environment.url;



@Injectable({
  providedIn: 'root'
})
export class VehiculoService {

  

  formGroup: FormGroup;

  constructor(protected http: HttpClient,
    protected router: Router,
    protected fb: FormBuilder) {

      this.formGroup = this.fb.group({
        cod_vehiculo: ['', [Validators.required]],
        marca: ['', [Validators.required]],
        modelo: ['', [Validators.required]],
        placa: ['', [Validators.required]],
        estado: [''],
      })      
      
      
    }



    get form (): FormGroup { return this.formGroup; }

    set fillForm(vehiculo: Vehiculo) {
    this.formGroup.get('cod_vehiculo').setValue(vehiculo.cod_vehiculo)
    this.formGroup.get('marca').setValue(vehiculo.marca)
    this.formGroup.get('modelo').setValue(vehiculo.modelo)
    this.formGroup.get('placa').setValue(vehiculo.placa)
    this.formGroup.get('estado').setValue(vehiculo.estado);
    }


    cargarVerhiculos(desde: number = 0){
    const url = `${ base_url}vehiculo/show?desde=${desde}`; 
    return this.http.get<any>(url)
    }

    getById(usuario: Vehiculo): Observable<Vehiculo> {
    return this.http.post<Vehiculo>(environment.baseUrl + 'vehiculo/showByID', usuario)
    }

    create(empleado: Vehiculo): Observable<Vehiculo> {
    return this.http.post<Vehiculo>(`${environment.baseUrl}vehiculo`, empleado)
    }

    edit(empleado: Vehiculo): Observable<Vehiculo> {
    return this.http.post<Vehiculo>(environment.baseUrl + 'vehiculo/update/' + empleado._id, empleado);
    }

    delete(empleado: Vehiculo): Observable<Vehiculo> {
    return this.http.delete<Vehiculo>(environment.baseUrl + 'vehiculo/' + empleado._id)
    }

    export(): Observable<Vehiculo> {
      return this.http.get<Vehiculo>(environment.baseUrl + 'vehiculo/exportar')
    }

    buscar() {
    }
  
    
}
