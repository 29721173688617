import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-informacion',
  templateUrl: './create-informacion.component.html',
  styleUrls: ['./create-informacion.component.scss']
})
export class CreateInformacionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
