import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Producto } from 'src/app/entities/modulos/producto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const base_url = environment.url;

@Injectable({
  providedIn: 'root'
})
export class ProductoService {
  formGroup: FormGroup;


  constructor(protected http: HttpClient,
              protected router: Router,
              protected fb: FormBuilder) {

                this.formGroup = this.fb.group({
                  id_categoria: ['', [Validators.required]],
                  nombre: ['', [Validators.required]],
                  descripcion: ['', [Validators.required]],
                  precio: ['', [Validators.required]],
                  stock: ['', [Validators.required]],
                  u_medida: ['', [Validators.required]],
                  imagen: ['', [Validators.required]],
                  estado: [''],
                })      
                
                
              }



  get form (): FormGroup { return this.formGroup; }

  set fillForm(proyecto: Producto) {
  this.formGroup.get('nombre').setValue(proyecto.nombre)
  this.formGroup.get('descripcion').setValue(proyecto.descripcion)
  this.formGroup.get('precio').setValue(proyecto.precio)
  this.formGroup.get('stock').setValue(proyecto.stock)
  this.formGroup.get('u_medida').setValue(proyecto.u_medida)
  this.formGroup.get('id_categoria').setValue(proyecto.id_categoria)
  this.formGroup.get('estado').setValue(proyecto.estado)
  this.formGroup.get('imagen').setValue(proyecto.imagen)

  
  }


 cargarProductos(desde: number = 0){
    const url = `${ base_url}producto/show?desde=${desde}`; 
    return this.http.get<any>(url)
  }

  getById(cargo: Producto): Observable<Producto> {
    return this.http.post<Producto>(environment.baseUrl + 'producto/showByID', cargo)
  }

  // create(cargo: Producto): Observable<Producto> {
  //   return this.http.post<Producto>(`${environment.baseUrl}producto`, cargo)
  // }

  create(data: Producto): Observable<Producto> {
    const fd = new FormData();
    fd.append('id_categoria',data.id_categoria);
    fd.append('nombre',data.nombre);
    fd.append('descripcion',data.descripcion);
    fd.append('stock',data.stock);
    fd.append('precio',data.precio);
    fd.append('u_medida',data.u_medida);
    fd.append('imagen',data.imagen);
    return this.http.post<Producto>(`${environment.baseUrl}producto`, fd)
  }



  edit(cargo: Producto): Observable<Producto> {
    return this.http.post<Producto>(environment.baseUrl + 'producto/update/' + cargo._id, cargo);
  }

  delete(cargo: Producto): Observable<Producto> {
    return this.http.delete<Producto>(environment.baseUrl + 'producto/' + cargo._id)
  }


  export(): Observable<Producto> {
    return this.http.get<Producto>(environment.baseUrl + 'producto/exportar')
  }


  buscar(
    tipo: 'cargo'|'tecnico'|'sede' |'usuario' |'dni' | 'email' | 'compra' | '_id',
    termino: string
  ) {
    const url = `${base_url}todo/coleccion/${tipo}/${termino}`;
    return this.http.get<any[]>(url)
    .pipe(
      map( (resp: any) => resp.resultados)
    );
  }


}
