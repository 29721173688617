import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Cargo } from 'src/app/entities/modulos/cargo';
import { Util } from 'src/app/utils/helpers/util';
// import { CreateZonaComponent } from '../modal/create-zona/create-zona.component';
// import { DeleteZonaComponent } from '../modal/delete-zona/delete-zona.component';
// import { EditZonaComponent } from '../modal/edit-zona/edit-zona.component';
import { CategoriaService } from '../service/categoria.service';
import { CreateCategoriaComponent } from '../modal/create-categoria/create-categoria.component';
import { EditCategoriaComponent } from '../modal/edit-categoria/edit-categoria.component';
import { DeleteCategoriaComponent } from '../modal/delete-categoria/delete-categoria.component';
import { Categoria } from 'src/app/entities/modulos/categoria';



@Component({
  selector: 'app-categoria-list',
  templateUrl: './categoria-list.component.html',
  styleUrls: ['./categoria-list.component.scss']
})
export class CategoriaListComponent  implements OnInit {


  public data: any = [];
  public categoria: any[] = [];
  public categoriaTemp: any[] = [];
  
  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalCategoria: number = 0;

  constructor(public categoriaService: CategoriaService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.cargarCategorias();
  }


  cargarCategorias(){
    this.cargando = true; 
    this.categoriaService.cargarCategorias(this.desde)
    .subscribe(({total, categoria}) => {
        this.totalCategoria = total;
        if(categoria.length !== 0) { 
          this.categoria = categoria;
          console.log(categoria);
          this.categoriaTemp = categoria;
        }
        this.cargando = false;
    })
  }


  openCreate(){
    const modalRef = this.modalService.open(CreateCategoriaComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then(res => {
        this.cargarCategorias();
      })
  }


  openEdit(data: Categoria) {
    const modalEdit = this.modalService.open(EditCategoriaComponent, { size: 'lg', backdrop: 'static' })
    modalEdit.componentInstance.categoria = data
    modalEdit.result.then(res => {
      // this.dataSource.updateTable(this.paginator.pageIndex)
      this.cargarCategorias();
    })
  }

  openDelete(data: Categoria) {
    const deleteModal = this.modalService.open(DeleteCategoriaComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.categoria = data
    deleteModal.result.then(res => {
      // this.dataSource.updateTable(this.paginator.pageIndex)
      this.cargarCategorias();
    })
  }

  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalCategoria) {
      this.desde -= valor;
    }
    this.cargarCategorias();
  }


  export(){
    this.categoriaService.export()
        .subscribe(res => { Util.download(res['data'], 'categorias'); console.log(res)});
  }


  buscar(termino: string) {
    // if(termino.length === 0 ) {
    //   return this.cargo = this.cargarCategorias;
    // }
    // this.cargoService.buscar('cargo', termino)
    // .subscribe(resultados => {
    //   this.cargo = resultados;
    // });
  }


}
